<template>
  <VCol cols="12">
    <SingleBanner
      :banner="selectedBanner"
      :languages="languagesList"
      :show="showDialog"
      :targets="targetList"
      @appearance="showDialog = $event"
      @save="saveBanner"
    />
    <VCol class="d-flex align-center" cols="12">
      <span class="page_title">
        Рекламный баннер
      </span>
      <VBtn
        class="text-uppercase ml-10 default-btn success white--text"
        rounded
        @click="createBanner"
      >
        <VIcon>
          mdi-plus
        </VIcon>
        Добавить
      </VBtn>
    </VCol>
    <VCol class="d-flex flex-wrap align-center" cols="12">
      <VCol class="d-flex align-start" cols="12">
        <VCol class="pa-0 d-flex align-center" cols="4">
          <VTextField
            v-model.lazy="fetchedBanner"
            placeholder="Название баннера"
            single-line
            solo
          />
        </VCol>
        <VCheckbox
          v-model="filterActive"
          class="ml-4 mt-2"
          label="Показать только активные"
          :ripple="false"
        />
      </VCol>
      <TransitionGroup class="d-flex flex-wrap justify-start ml-1 mr-2 mt-4" name="banners">
        <div
          v-for="banner in banners"
          :key="banner.id"
          class="banner-wrapper my-2 mx-2 d-flex flex-wrap"
          @click="bannerSelected(banner)"
        >
          <div
            class="banner d-flex align-end justify-end mx-2 mt-1"
            :style="{ backgroundImage: `url(${banner.imageUrl})`}"
          >
          </div>
          <span class="input-title text-start px-2"> {{ banner.title }} </span>
        </div>
      </TransitionGroup>
    </VCol>
    <VPagination
      v-if="bannersCount / limit > 1"
      v-model="currentPage"
      color="success"
      :length="Math.ceil(bannersCount / limit)"
      total-visible="7"
    />
  </VCol>
</template>

<script>
import { serializeBanner } from '@/serializers/bannerSerializer';
import SingleBanner from '@/components/SingleBanner.vue';
import {
  getBanners,
  getBannerByTitle,
  createBanner,
  updateBanner,
  bannerPageLimit,
  getTargetList,
  getLanguagesList,
} from '@/api/api';

export default {
  components: {
    SingleBanner,
  },
  data() {
    return {
      showDialog: false,
      selectedBanner: serializeBanner(),
      isNewBanner: false,
      fetchedBanner: null,
      fetchedBanners: [],
      banners: [],
      currentPage: 1,
      bannersCount: 0,
      limit: bannerPageLimit,
      filterActive: false,
      targetList: [],
      languagesList: [],
    };
  },
  methods: {
    bannerSelected(banner) {
      this.showDialog = true;
      this.selectedBanner = banner;
      this.isNewBanner = false;
    },
    createBanner() {
      this.showDialog = true;
      this.isNewBanner = true;
      this.selectedBanner = serializeBanner();
    },
    saveBanner(banner) {
      if (this.isNewBanner) {
        this.postNewBanner(banner);
      } else {
        this.changeExistingBanner(banner);
      }
    },
    fetchBanners() {
      getBanners(this.currentPage).then(
        (response) => {
          this.banners = response.banners;
          this.bannersCount = response.count;
        },
      );
    },
    postNewBanner(banner) {
      createBanner(banner).then((result) => {
        this.banners.unshift(result);
      });
    },
    changeExistingBanner(banner) {
      updateBanner(banner).then((result) => {
        this.banners.forEach((eachBanner, index) => {
          if (eachBanner.id === result.id) {
            Object.assign(this.banners[index], result);
          }
        });
      });
    },
    fetchBannersByTitle() {
      getBannerByTitle(this.fetchedBanner, this.filterActive)
        .then((result) => {
          this.currentPage = undefined;
          this.banners = result.banners;
          this.bannersCount = 0;
        });
    },
    fetchTargetList() {
      getTargetList().then((result) => {
        this.targetList = result;
      });
    },
    fetchLanguagesList() {
      getLanguagesList().then((result) => {
        this.languagesList = result;
      });
    },
  },
  created() {
    this.fetchBanners();
    this.fetchTargetList();
    this.fetchLanguagesList();
  },
  watch: {
    currentPage(page) {
      if (page > 0) { this.fetchBanners(); }
    },
    fetchedBanner(newValue) {
      if (newValue && newValue.length > 3) {
        this.fetchBannersByTitle();
      } else if (newValue.length === 0) {
        this.fetchBanners();
      }
    },
    filterActive(value) {
      if (value) {
        this.fetchBannersByTitle();
      } else {
        this.fetchBanners();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/mixin.scss';

.banner-wrapper {
  width: 10vw;
  height: 10vw;
  min-width: 200px;
  min-height: 210px;
  position: relative;
  border-radius: .5em;
  border: 2px solid $transparent_gray_color;
  cursor: pointer;
  transition:transform .3s, opacity .2s;
  .active {
    background-color: $transparent_green_color;
  }
  .banner {
    background-color: $transparent_gray_color;
    width: 100%;
    height: 65%;
    border-radius: .5em;
    transition: background-position .3s, border-radius .3s;
    background-position: center;
    background-size: cover;
    overflow: hidden;
  }

  &:hover { transform: scale(1.1); }
  &:nth-child(odd):hover .banner { background-position: left; }

  &:hover .banner{
    background-position: right;
    border-radius: 1em;
    z-index: 2;
  }

  &:hover span { color: $default_gray_color; }
  &:active {
    transform: scale(1.5);
    opacity: 0;
  }
  span {
    width: 100%;
    color: $default_gray_title_color;
  }
  a {
    width: 100%;
    height: 100%;
    text-decoration: none;
  }
}
.banner-wrapper {
  transition: all .4s cubic-bezier(.85,.27,.52,1);
}
.banners-enter, .banners-leave-to {
  opacity: 0;
  transform: translateY(0%);
}
.banners-leave-active {
  position: absolute;
}
</style>
