<template>
  <VDialog v-model="dialog" width="50vw">
    <VCard>
      <VCardTitle class="mb-4">
        <span class="page_title mr-4">
          {{ bannerToEdit.title ? 'Редактирование' : 'Создание' }} баннера
        </span>
        <VCheckbox
          v-model="state"
          class="mt-0"
          color="error"
          hide-details
          :label="bannerToEdit.isActive ? 'деактивировать' : 'деактивирован'"
        />
      </VCardTitle>
      <VForm ref="form">
        <VCol class="pa-0 d-flex flex-wrap align-center" cols="12">
          <NamedRuledTextField
            class="py-1"
            flat
            input-width="8"
            placeholder="Введите название"
            :text="bannerToEdit.title"
            title="Название"
            @update="bannerToEdit.title = $event"
          />
          <ImageUpload
            class="pl-1 py-0 pr-6"
            description="Соотношение сторон 1:2 ( высота : ширина )"
            :image="bannerToEdit.imageUrl"
            image-type="banner"
            ratio="1:2"
            ratio-required
            required
            @update="bannerToEdit.imageUrl = $event.logo"
          />
          <VCol cols="3">
            <span class="input-title pl-2">Тип баннера</span>
          </VCol>
          <VCol cols="8">
            <VSelect
              v-model="bannerToEdit.type"
              class="mx-2"
              dense
              hide-details
              hide-no-data
              hide-selected
              item-text="title"
              :items="bannerType"
              outlined
              placeholder="Выберите тип баннера"
            />
          </VCol>
          <VCol cols="3">
            <span class="input-title pl-2">Выбор действия</span>
          </VCol>
          <VCol class="pa-0" cols="9">
            <VCol cols="5">
              <VSelect
                v-model="bannerToEdit.action.type"
                class="mx-2"
                dense
                hide-details
                hide-no-data
                hide-selected
                item-text="title"
                :items="actionTypes"
                outlined
                placeholder="Выберите тип действия"
              />
            </VCol>
          </VCol>
          <Transition name="title">
            <NamedRuledTextField
              v-if="bannerToEdit.action.type === 'open_web_view'"
              class="py-1"
              input-width="8"
              placeholder="Введите название действия"
              :text="bannerToEdit.action.title"
              title="Название действия"
              @update="bannerToEdit.action.title = $event"
            />
          </Transition>
          <NamedRuledTextField
            class="py-1"
            flat
            input-width="8"
            placeholder="Дип-линк или еще что"
            :text="bannerToEdit.action.url"
            title="Ссылка"
            @update="bannerToEdit.action.url = $event"
          />
          <VCol cols="3">
            <span class="input-title pl-4">Город</span>
          </VCol>
          <VCol cols="8">
            <VAutocomplete
              v-model="bannerToEdit.cities"
              class="mx-2"
              deletable-chips
              dense
              hide-details
              hide-no-data
              hide-selected
              item-text="title"
              item-value="pk"
              :items="cities"
              multiple
              outlined
              placeholder="Выберите город"
              small-chips
            />
          </VCol>
          <VCol cols="3">
            <span class="input-title pl-4">Платформа</span>
          </VCol>
          <VCol cols="8">
            <VAutocomplete
              v-model="bannerToEdit.targets"
              class="mx-2"
              deletable-chips
              dense
              hide-details
              hide-no-data
              hide-selected
              item-text="title"
              item-value="code"
              :items="targets"
              multiple
              outlined
              placeholder="Выберите платформу"
              small-chips
            />
          </VCol>
          <VCol cols="3">
            <span class="input-title pl-4">Язык</span>
          </VCol>
          <VCol cols="8">
            <VAutocomplete
              v-model="bannerToEdit.languages"
              class="mx-2"
              deletable-chips
              dense
              hide-details
              hide-no-data
              hide-selected
              item-text="title"
              item-value="code"
              :items="languages"
              multiple
              outlined
              placeholder="Выберите язык"
              :rules="[(value) => !!value.length || 'Выберите язык']"
              small-chips
            />
          </VCol>
          <VCol cols="3">
            <span class="input-title pl-4">Позиция</span>
          </VCol>
          <VCol cols="2">
            <VTextField
              v-model="bannerToEdit.position"
              class="pl-2"
              color="success"
              dense
              outlined
              @keypress="parseNumber"
            />
          </VCol>
          <VCol cols="6" />
          <VCol cols="3">
            <span class="input-title pl-4">Промежуток</span>
          </VCol>
          <VCol class="d-flex" cols="8">
            <DateTimeSelection
              :preselected-date="startDate"
              :preselected-time="startTime"
              @date-change="startDate = $event"
              @time-change="startTime = $event"
            />
            <DateTimeSelection
              color="error"
              :preselected-date="endDate"
              :preselected-time="endTime"
              text="до"
              @date-change="endDate = $event"
              @time-change="endTime = $event"
            />
          </VCol>
          <span v-if="preventSave" class="attention">☝️</span>
        </VCol>
      </VForm>
      <VCol class="d-flex justify-center" cols="12">
        <VBtn
          class="default-btn success white--text px-12"
          :disabled="preventSave"
          rounded
          @click="submitBanner()"
        >
          СОХРАНИТЬ
        </VBtn>
      </VCol>
    </VCard>
  </VDialog>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import { parseNumber } from '@/helpers/price';
import NamedRuledTextField from '@/components/NamedRuledTextField.vue';
import ImageUpload from '@/components/ImageUpload.vue';
import DateTimeSelection from '@/components/DateTimeSelection.vue';

export default {
  components: {
    NamedRuledTextField,
    ImageUpload,
    DateTimeSelection,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    banner: {
      type: Object,
    },
    targets: {
      type: Array,
    },
    languages: {
      type: Array,
    },
  },
  emits: ['save', 'appearance'],
  data() {
    return {
      dialog: this.show,
      selectedBannerType: '',
      selectedBannerActionType: '',
      selectedCities: this.banner.cities,
      startDate: moment(this.banner.dateStart).format('YYYY-MM-DD'),
      startTime: moment(this.banner.dateStart).format('HH:mm'),
      endDate: moment(this.banner.dateEnd).format('YYYY-MM-DD'),
      endTime: moment(this.banner.dateEnd).format('HH:mm'),
      bannerToEdit: { ...JSON.parse(JSON.stringify(this.banner)) },
      states: [
        { title: 'активный', value: true },
        { title: 'удаленый', value: false },
      ],
      parseNumber,
    };
  },
  computed: {
    ...mapGetters(['cities']),
    state: {
      get() {
        return !this.bannerToEdit.isActive;
      },
      set() {
        this.bannerToEdit.isActive = !this.bannerToEdit.isActive;
      },
    },
    bannerType() {
      return [
        {
          value: 'main_slider',
          title: 'Главный слайдер',
        },
        {
          value: 'promotion_slider',
          title: 'Рекламный слайдер',
        },
      ];
    },
    actionTypes() {
      return [
        {
          value: 'open_url',
          title: 'переход по адресу',
        },
        {
          value: 'open_web_view',
          title: 'открыть в приложении',
        },
      ];
    },
    preventSave() {
      return this.startDate > this.endDate || this.endDate < moment().format('YYYY-MM-DD');
    },
  },
  created() {
    this.getCities();
  },
  methods: {
    ...mapActions(['getCities']),
    submitBanner() {
      if (this.$refs.form.validate()) {
        const startDateTime = moment(`${this.startDate} ${this.startTime}`).format('YYYY-MM-DDTHH:mm:ss');
        const endDateTime = moment(`${this.endDate} ${this.endTime}`).format('YYYY-MM-DDTHH:mm:ss');
        if (startDateTime > endDateTime) {
          this.preventSave = true;
        } else {
          this.bannerToEdit.dateStart = startDateTime;
          this.bannerToEdit.dateEnd = endDateTime;
          this.$emit('save', this.bannerToEdit);
          this.dialog = false;
        }
      }
    },
  },
  watch: {
    show(bool) {
      this.dialog = bool;
    },
    dialog(dialog) {
      if (dialog) {
        this.bannerToEdit = { ...JSON.parse(JSON.stringify(this.banner)) };
        this.startDate = moment(this.banner.dateStart).format('YYYY-MM-DD');
        this.startTime = moment(this.banner.dateStart).format('HH:mm');
        this.endDate = moment(this.banner.dateEnd).format('YYYY-MM-DD');
        this.endTime = moment(this.banner.dateEnd).format('HH:mm');
        this.$refs.form.resetValidation();
      } else {
        this.$emit('appearance', dialog);
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.attention{
  animation: attention 1s infinite;
}

.title-enter-active,
.title-leave-active {
  transition: opacity .5s, transform .5s;
}

.title-leave-to {
  opacity: 0;
  transform: translateX(-100%);
}

.title-enter {
  opacity: 0;
  position: absolute;
  transform: translateX(-100%);
}

@keyframes attention {
  from, to {
    transform: scale(1) rotate(0deg);
  }
  50% {
    transform: scale(2) rotate(-90deg);
  }
}
</style>
