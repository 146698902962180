var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VCol',{staticClass:"d-flex align-center pa-0",attrs:{"cols":_vm.cols}},[_c('span',{staticClass:"input-title mr-4",class:`${_vm.color}--text`},[_vm._v(_vm._s(_vm.text))]),_c('VCol',{staticClass:"pa-0",attrs:{"cols":_vm.calendarWidth}},[_c('VMenu',{attrs:{"close-on-content-click":false,"min-width":"auto","nudge-right":40,"offset-y":"","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('VTextField',_vm._g(_vm._b({attrs:{"append-icon":"mdi-calendar","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'VTextField',attrs,false),on))]}}]),model:{value:(_vm.dateAppearance),callback:function ($$v) {_vm.dateAppearance=$$v},expression:"dateAppearance"}},[_c('VDatePicker',{on:{"input":function($event){return _vm.updateField($event, 'date')}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('VCol',{staticClass:"pr-0 pl-2 d-flex justify-center",attrs:{"cols":_vm.timeWidth}},[_c('input',{ref:"hours",staticClass:"hours time",style:({
        height: _vm.timeWidth * 6 + 'px',
        width: _vm.timeWidth * 6 + 'px',
      }),attrs:{"max":"23","min":"0","type":"number"},domProps:{"value":_vm.hours},on:{"change":function($event){return _vm.checkHours($event.target.value)}}}),_vm._v(" : "),_c('input',{ref:"minutes",staticClass:"minutes time",style:({
        height: _vm.timeWidth * 6 + 'px',
        width: _vm.timeWidth * 6 + 'px',
      }),attrs:{"max":"59","min":"0","type":"number"},domProps:{"value":_vm.minutes},on:{"change":function($event){return _vm.checkMinutes($event.target.value)}}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }