<template>
  <VCol class="d-flex align-center pa-0" :cols="cols">
    <span class="input-title mr-4" :class="`${color}--text`">{{ text }}</span>
    <VCol class="pa-0" :cols="calendarWidth">
      <VMenu
        v-model="dateAppearance"
        :close-on-content-click="false"
        min-width="auto"
        :nudge-right="40"
        offset-y
        transition="scale-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <VTextField
            v-model="date"
            v-bind="attrs"
            append-icon="mdi-calendar"
            readonly
            v-on="on"
          />
        </template>
        <VDatePicker
          v-model="date"
          @input="updateField($event, 'date')"
        />
      </VMenu>
    </VCol>
    <VCol class="pr-0 pl-2 d-flex justify-center" :cols="timeWidth">
      <input
        ref="hours"
        class="hours time"
        max="23"
        min="0"
        :style="{
          height: timeWidth * 6 + 'px',
          width: timeWidth * 6 + 'px',
        }"
        type="number"
        :value="hours"
        @change="checkHours($event.target.value)"
      />
      :
      <input
        ref="minutes"
        class="minutes time"
        max="59"
        min="0"
        :style="{
          height: timeWidth * 6 + 'px',
          width: timeWidth * 6 + 'px',
        }"
        type="number"
        :value="minutes"
        @change="checkMinutes($event.target.value)"
      />
    </VCol>
  </VCol>
</template>

<script>
export default {
  props: {
    cols: {
      type: [String, Number],
      default: 6,
    },
    text: {
      type: String,
      default: 'от',
    },
    calendarWidth: {
      type: [String, Number],
      default: 5,
    },
    timeWidth: {
      type: [String, Number],
      default: 5,
    },
    preselectedDate: {
      type: String,
    },
    preselectedTime: {
      type: String,
    },
    color: {
      type: String,
      default: 'success',
    },
  },
  data() {
    return {
      date: this.preselectedDate,
      time: this.preselectedTime,
      hours: this.preselectedTime.split(':')[0],
      minutes: this.preselectedTime.split(':')[1],
      dateAppearance: false,
    };
  },
  methods: {
    updateField(data, key) {
      this[key] = data;
      this.$emit(`${key}-change`, data);
      this.dateAppearance = false;
    },
    checkHours(hours) {
      this.hours = parseInt(hours, 10) > 23 ? 23 : parseInt(hours, 10);
      this.$refs.hours.value = this.hours;
      this.updateField(`${this.hours}:${this.minutes}`, 'time');
    },
    checkMinutes(minutes) {
      this.minutes = parseInt(minutes, 10) > 59 ? 59 : parseInt(minutes, 10);
      this.$refs.minutes.value = this.minutes;
      this.updateField(`${this.hours}:${this.minutes}`, 'time');
    },
  },
  watch: {
    preselectedDate(newDate) {
      this.date = newDate;
    },
    preselectedTime(newTime) {
      this.time = newTime;
      this.hours = newTime.split(':')[0];
      this.minutes = newTime.split(':')[1];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixin.scss";

.time {
  border: 1px solid $transparent_gray_color;
  border-radius: 10px;
  text-align: center;
  &:focus {
    outline: none;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
</style>
